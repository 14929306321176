/* eslint-disable*/
import { ChiffreAdressesOverviewComponent } from '../../../../chiffre-addresses/chiffre-adresses-overview/chiffre-adresses-overview.component';
import { UserAdministrationOverviewComponent } from '../../../../user-administration/user-administration-overview/user-administration-overview.component';
import { SegaSubscriptionComponent } from '../../../../company/company-detail/company-sega/sega-subscription/sega-subscription.component';
import { SendInvitationComponent } from '../../../../company/company-detail/company-sega/send-invitation/send-invitation.component';
import { CompanyProfileCreateComponent } from '../../../../company/company-detail/company-profile/company-profile-create/company-profile-create.component';
import { CompanyEditComponent } from '../../../../company/company-edit/company-edit.component';
import { CompanyNewComponent } from '../../../../company/company-new/company-new.component';
import { OfferOrderCorrectComponent } from '../../../../offer-order-management/offer-order-correct/offer-order-correct.component';
import { OfferOrderEditComponent } from '../../../../offer-order-management/offer-order-edit/offer-order-edit.component';
import { OfferOrderNewComponent } from '../../../../offer-order-management/offer-order-new/offer-order-new.component';
import { UserRolePermissionPolicy } from '../user-role-permissions-policy.model';
import { VideoOverviewTableComponent } from '../../../../video-administration/video-overview/video-overview-table.component';
import { ResellerPermissions } from '../reseller-permissions.model';
import { NavLinkEnum } from '../../../../main-layout/nav.model';
/* eslint-enable*/

export class SalesManagerPolicy extends UserRolePermissionPolicy {
  private static readonly forbiddenComponents: string[] = [
    'ChiffreAdressesOverviewComponent',
    'UserAdministrationOverviewComponent',
    'VideoOverviewTableComponent',
    'OfferOrderNewComponent',
    'OfferOrderEditComponent',
    'OfferOrderCorrectComponent',
    'CompanyNewComponent',
    'CompanyEditComponent',
    'CompanyProfileCreateComponent',
    'SendInvitationComponent',
    'SegaSubscriptionComponent',
    'CompanyNamesManagementComponent',
    'CompanyProfileV3CreateComponent'
  ];

  private static readonly forbiddenNavigationLinks: NavLinkEnum[] = [
    NavLinkEnum.userManagement,
    NavLinkEnum.chiffreAddressesManagement,
    NavLinkEnum.videoManagement,
    NavLinkEnum.internalProductName
  ];

  constructor(resellerPermissions: ResellerPermissions) {
    super(SalesManagerPolicy.forbiddenComponents,
      SalesManagerPolicy.forbiddenNavigationLinks,
      resellerPermissions);
  }
}
